import React, { useState } from 'react';
import { Eye } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface POVSceneFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const SCENE_SUGGESTIONS = [
  'looking down at a busy street market',
  'gazing up at starry night sky',
  'peering through a window at a garden',
  'sitting in a cozy reading nook',
  'standing at the edge of a cliff',
  'looking out from a treehouse',
  'watching sunset from a beach',
  'viewing city from skyscraper'
] as const;

const PERSPECTIVE_OPTIONS = [
  'First Person',
  'Looking Down',
  'Looking Up',
  'Through Window',
  'From Above',
  'From Below'
] as const;

export function POVSceneForm({ onImageGenerated, setIsLoading }: POVSceneFormProps) {
  const [scene, setScene] = useState('');
  const [perspective, setPerspective] = useState<string>(PERSPECTIVE_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!scene.trim()) {
      toast.error('Please describe the scene');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a black and white line art coloring page from a ${perspective.toLowerCase()} perspective, ${scene}. The design should include:
      - Clean, clear outlines perfect for coloring
      - Accurate perspective and depth
      - Interesting viewpoint that creates immersion
      - Simple background elements that enhance the scene
      - White background with no solid fills
      - Easy-to-color spaces between elements
      - Moderate detail level suitable for both children and adults
      Make it engaging and unique with a strong sense of being in the scene.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Choose Perspective
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {PERSPECTIVE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setPerspective(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  perspective === option
                    ? 'bg-indigo-100 dark:bg-indigo-900/40 text-indigo-700 dark:text-indigo-400 border-2 border-indigo-500'
                    : 'bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label htmlFor="scene" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Describe Your Scene
          </label>
          <input
            id="scene"
            type="text"
            value={scene}
            onChange={(e) => setScene(e.target.value)}
            placeholder="e.g., looking down at a busy street market"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            required
          />
          
          <div className="mt-3">
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Quick suggestions:</p>
            <div className="flex flex-wrap gap-2">
              {SCENE_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setScene(suggestion)}
                  className="px-3 py-1 text-sm bg-indigo-50 dark:bg-indigo-900/20 text-indigo-700 dark:text-indigo-400 rounded-full hover:bg-indigo-100 dark:hover:bg-indigo-900/40 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Eye className="w-5 h-5" />
          Generate POV Scene
        </button>
      </form>
    </>
  );
}