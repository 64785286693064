import React, { useState } from 'react';
import { Flag } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';
import { Search } from 'lucide-react';
import countries from '../data/countries.json';

interface FlagGeneratorFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export function FlagGeneratorForm({ onImageGenerated, setIsLoading }: FlagGeneratorFormProps) {
  const [country, setCountry] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const filteredCountries = countries.filter(c => 
    c.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!country.trim()) {
      toast.error('Please select a country');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a hand-drawn coloring page of the ${country} flag. The scene should show:
      
      - A naturally waving flag with gentle folds and ripples in the fabric
      - A realistic wooden or metal flagpole with details like joints and fixtures
      - Natural-looking clouds with varied shapes and textures in the background
      - Some ground elements like grass, stones, or a base for the flagpole
      - Artistic, sketchy line work that feels hand-drawn rather than computer-generated
      - Varied line weights to add depth and dimension
      - Organic, imperfect lines that give character to the drawing
      
      The style should be:
      - Black and white line art suitable for coloring
      - No solid black fills
      - Natural and artistic rather than geometric or vector-like
      - Detailed enough to be interesting but not overwhelming
      - Balanced composition with good white space
      
      Make it look like it was drawn by hand with natural imperfections and artistic flair.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  const handleSelectCountry = (selectedCountry: string) => {
    setCountry(selectedCountry);
    setSearchTerm('');
    setIsOpen(false);
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Select a Country
          </label>
          <div className="relative mt-1">
            <div className="relative">
              <input
                type="text"
                value={country || searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setIsOpen(true);
                  setCountry('');
                }}
                onClick={() => setIsOpen(true)}
                className="w-full px-4 py-2 pl-10 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                placeholder="Search for a country..."
              />
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 dark:text-gray-500" />
            </div>
            
            {isOpen && (
              <div className="absolute z-10 mt-1 w-full bg-white dark:bg-gray-700 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600 max-h-60 overflow-auto">
                {filteredCountries.length > 0 ? (
                  filteredCountries.map((countryName) => (
                    <button
                      key={countryName}
                      type="button"
                      onClick={() => handleSelectCountry(countryName)}
                      className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-white"
                    >
                      {countryName}
                    </button>
                  ))
                ) : (
                  <div className="px-4 py-2 text-gray-500 dark:text-gray-400">
                    No countries found
                  </div>
                )}
              </div>
            )}
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Choose any country to create its flag for coloring
          </p>
        </div>

        <button
          type="submit"
          disabled={!country}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Flag className="w-5 h-5" />
          Generate Flag Design
        </button>
      </form>
    </>
  );
}