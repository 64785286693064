import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreditGuard } from '../components/CreditGuard';
import { Palette, Heart, PenTool, Brain, Sparkles, Star, Zap, Moon, ArrowRight } from 'lucide-react';

const recentUpdates = [
  {
    title: "Cover Generator",
    description: "Create beautiful coloring book covers",
    path: "/kids/cover-generator",
    date: "Feb 18, 2024",
    icon: Sparkles,
    color: "blue"
  },
  {
    title: "Mother's Day Doodles",
    description: "Create special Mother's Day designs",
    path: "/kids/mothers-day",
    date: "Feb 18, 2024",
    icon: Sparkles,
    color: "purple"
  },
  {
    title: "Father's Day Doodles",
    description: "Create special Father's Day designs",
    path: "/kids/fathers-day",
    date: "Feb 18, 2024",
    icon: Moon,
    color: "emerald"
  },
  {
    title: "St. Patrick's Day",
    description: "Create festive St. Patrick's Day designs",
    path: "/kids/st-patricks",
    date: "Feb 18, 2024",
    icon: Sparkles,
    color: "green"
  },
  {
    title: "Flag Generator",
    description: "Create beautiful flag designs for coloring",
    path: "/kids/flag-generator",
    date: "Feb 18, 2024",
    icon: Sparkles,
    color: "blue"
  }
];

const categories = [
  {
    id: 'kids',
    title: 'Kids Corner',
    description: 'Simple and fun designs perfect for little ones',
    icon: Palette,
    color: 'blue',
    tools: [
      {
        id: 'ramadhan',
        title: 'Ramadhan Coloring',
        description: 'Create Malaysian Ramadhan scenes',
        icon: Moon,
        path: 'ramadhan'
      },
      { id: 'alphabet', title: 'Alphabet Art', description: 'Create fun alphabet learning pages' },
      { id: 'doodle-letters', title: 'Doodle Letters', description: 'Create fun doodle-filled letters' },
      { id: 'emoji-style', title: 'Emoji Style', description: 'Create cute emoji-style designs' },
      { id: 'cover-simple', title: 'Cover - Simple & Easy', description: 'Create simple and easy covers' },
      { id: 'cover-monster', title: 'Cover - Monster Fun', description: 'Create fun monster-themed covers' },
      { id: 'cover-vehicles', title: 'Cover - Happy Vehicles', description: 'Create cheerful vehicle-themed covers' },
      { id: 'cover-food', title: 'Cover - Cute Food', description: 'Create adorable food-themed covers' },
      { id: 'cover-birds', title: 'Cover - Flying Birds', description: 'Create delightful flying bird covers' },
      { id: 'simple', title: 'Simple & Easy', description: 'Create simple and easy designs' },
      { id: 'superhero-name', title: 'Superhero Name', description: 'Design superhero-themed names' },
      { id: 'monster-fun', title: 'Monster Fun', description: 'Create fun monster designs' },
      { id: 'happy-vehicles', title: 'Happy Vehicles', description: 'Design cheerful vehicle illustrations' },
      { id: 'food-coloring', title: 'Food Coloring', description: 'Create cute food-themed designs' },
      { id: 'circus-clowns', title: 'Circus Clowns', description: 'Design fun circus clown scenes' },
      { id: 'flying-birds', title: 'Flying Birds', description: 'Create delightful bird illustrations' }
    ]
  },
  {
    id: 'adult',
    title: 'Adult Coloring',
    description: 'Complex and detailed designs for adults',
    icon: Brain,
    color: 'purple',
    tools: [
      { id: 'cover-holiday', title: 'Cover - Holiday Theme', description: 'Create colorful holiday-themed covers' },
      { id: 'cover-mandala', title: 'Cover - Mandala Art', description: 'Create colorful mandala designs' },
      { id: 'cover-tarot', title: 'Cover - Tarot Cards', description: 'Create colorful mystical card designs' },
      { id: 'mandala', title: 'Mandala Art', description: 'Create intricate mandala designs' },
      { id: 'tarot-cards', title: 'Tarot Cards', description: 'Design mystical tarot card illustrations' },
      { id: 'miniature-world', title: 'Miniature World', description: 'Create enchanting miniature scenes' },
      { id: 'mystery-characters', title: 'Mystery Characters', description: 'Design enigmatic character scenes' },
      { id: 'quarrel-scene', title: 'Quarrel Scene', description: 'Create amusing disagreement scenes' }
    ]
  },
  {
    id: 'cozy',
    title: 'Cozy Collection',
    description: 'Warm and inviting scenes for all ages',
    icon: Heart,
    color: 'red',
    tools: [
      { id: 'cover-friends', title: 'Cover - Cozy Friend', description: 'Create adorable cover designs with friendly pairs' },
      { id: 'cover-building', title: 'Cover - Cozy Building', description: 'Create beautiful isometric building designs' },
      { id: 'cover-camping', title: 'Cover - Travel & Camping', description: 'Create colorful outdoor adventure scenes' },
      { id: 'cover-room', title: 'Cover - Room Corner', description: 'Create colorful isometric room designs' },
      { id: 'friends', title: 'Cozy Friends', description: 'Design heartwarming animal friendship scenes' },
      { id: 'building', title: 'Cozy Building', description: 'Generate cozy architectural designs' },
      { id: 'travel-camping', title: 'Travel & Camping', description: 'Create outdoor adventure scenes' },
      { id: 'funny-shop', title: 'Scene at Shop', description: 'Design delightful shopping scenes' },
      { id: 'animal-profession', title: 'Animal Profession', description: 'Create professional animal characters' },
      { id: 'room-corner', title: 'Room Corner', description: 'Design isometric room corners' }
    ]
  },
  {
    id: 'doodle',
    title: 'Doodle & Art',
    description: 'Creative patterns and artistic designs',
    icon: PenTool,
    color: 'teal',
    tools: [
      { id: 'cover-pattern', title: 'Cover - Doodle Pattern', description: 'Create colorful doodle patterns' },
      { id: 'cover-quotes', title: 'Cover - Quotes + Doodle', description: 'Create colorful quote designs' },
      { id: 'cover-cursive', title: 'Cover - Cursive Art', description: 'Create colorful cursive designs' },
      { id: 'cover-decorative', title: 'Cover - Decorative Text', description: 'Create colorful text designs' },
      { id: 'cover-floral', title: 'Cover - Floral Pattern', description: 'Create colorful floral patterns' },
      { id: 'cover-bubble', title: 'Cover - Bubble Text', description: 'Create colorful bubble text designs' },
      { id: 'pattern', title: 'Doodle Pattern', description: 'Generate seamless doodle patterns' },
      { id: 'quotes', title: 'Quotes + Doodle', description: 'Create inspiring quote designs' },
      { id: 'cursive', title: 'Cursive Art', description: 'Transform words into beautiful designs' },
      { id: 'decorative', title: 'Decorative Text', description: 'Design beautiful text arrangements' },
      { id: 'floral', title: 'Floral Patterns', description: 'Create beautiful floral designs' },
      { id: 'bubble', title: 'Bubble Text', description: 'Create fun bubble letter designs' },
      { id: 'anything', title: 'Doodle Anything', description: 'Create charming doodle patterns' }
    ]
  }
];

const features = [
  {
    icon: Sparkles,
    title: '50+ Professional Tools',
    description: 'Access our extensive collection of specialized design tools'
  },
  {
    icon: Star,
    title: 'High-Quality Output',
    description: 'Generate professional-grade coloring pages suitable for publishing'
  },
  {
    icon: Zap,
    title: 'Fast Generation',
    description: 'Create beautiful designs in seconds with AI assistance'
  }
];

function Dashboard() {
  const navigate = useNavigate();

  return (
    <CreditGuard>
      <div className="space-y-8">
        {/* Hero Section */}
        <div className="text-center space-y-6">
          <h1 className="text-4xl font-bold text-gray-900">
            AI Coloring Page Generator
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Create beautiful, custom coloring pages in seconds with our AI-powered platform.
            Perfect for teachers, parents, and creative minds.
          </p>
          
          {/* Feature Badges */}
          <div className="flex flex-wrap justify-center gap-4">
            {features.map((feature) => (
              <div key={feature.title} className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-full">
                <feature.icon className="w-5 h-5" />
                <span className="font-medium">{feature.title}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Categories Grid */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {categories.map((category) => (
            <div
              key={category.id}
              className={`bg-${category.color}-50 dark:bg-gray-800 p-6 rounded-xl border border-${category.color}-100 dark:border-gray-700`}
            >
              <div className="flex items-center gap-3 mb-3">
                <div className={`p-2 bg-${category.color}-100 dark:bg-${category.color}-900 rounded-lg`}>
                  <category.icon className={`w-6 h-6 text-${category.color}-600`} />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900 dark:text-white">{category.title}</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">{category.tools.length} tools</p>
                </div>
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">{category.description}</p>
              <button
                onClick={() => navigate(`/${category.id}`)}
                className={`w-full px-4 py-2 bg-${category.color}-100 dark:bg-${category.color}-900/30 text-${category.color}-700 dark:text-${category.color}-400 rounded-lg hover:bg-${category.color}-200 dark:hover:bg-${category.color}-900/50 transition-colors text-sm font-medium`}
              >
                Explore Tools
              </button>
            </div>
          ))}
        </div>
        
        {/* Recent Updates Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700 overflow-hidden">
          <div className="p-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Recent Updates</h2>
            <div className="grid gap-6 md:grid-cols-3">
              {recentUpdates.map((update) => {
                const Icon = update.icon;
                return (
                  <div
                    key={update.title}
                    onClick={() => navigate(update.path)}
                    className="group cursor-pointer"
                  >
                    <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-6 transition-all duration-300 hover:shadow-md">
                      <div className="flex items-start justify-between mb-4">
                        <div className={`p-2 bg-${update.color}-50 dark:bg-${update.color}-900/20 rounded-lg group-hover:scale-110 transition-transform`}>
                          <Icon className={`w-5 h-5 text-${update.color}-500 dark:text-${update.color}-400`} />
                        </div>
                        <span className="text-xs text-gray-500 dark:text-gray-400">{update.date}</span>
                      </div>
                      <h3 className="font-medium text-gray-900 dark:text-white mb-2">{update.title}</h3>
                      <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">{update.description}</p>
                      <div className="flex items-center text-sm text-blue-600 dark:text-blue-400 group-hover:translate-x-1 transition-transform">
                        Try now
                        <ArrowRight className="w-4 h-4 ml-1" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </CreditGuard>
  );
}

export default Dashboard;