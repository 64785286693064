import React from 'react';

interface PageLayoutProps {
  title: string;
  description: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export function PageLayout({ 
  title,
  description,
  icon,
  children
}: PageLayoutProps) {
  return (
    <div className="min-h-[calc(100vh-2rem)]">
      {/* Header */}
      <div className="mb-6 text-gray-900 dark:text-white">
        <div className="flex items-center justify-center gap-2 mb-2">
          {icon}
          <h1 className="text-2xl sm:text-3xl font-bold">{title}</h1>
        </div>
        <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 text-center max-w-2xl mx-auto">
          {description}
        </p>
      </div>

      {/* Main Content */}
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-100 dark:border-gray-700">
        {children}
      </div>
    </div>
  );
}