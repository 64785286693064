import React, { useState } from 'react';
import { Moon } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface RamadhanFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const SCENE_SUGGESTIONS = [
  {
    title: 'Bazaar Ramadhan',
    description: 'Bustling Bazaar Ramadhan with traditional Malaysian food stalls',
    pretext: 'e.g., makcik selling nasi lemak, satay stall with pelita lights, roti john stall with queue, murtabak stall with colorful drinks'
  },
  {
    title: 'Masjid Scenes',
    description: 'Beautiful Malaysian mosque scenes during Ramadhan',
    pretext: 'e.g., solat tarawih at kampung mosque, breaking fast at surau, kids mengaji with ustaz, people giving sedekah'
  },
  {
    title: 'Family Moments',
    description: 'Heartwarming Malaysian family moments during Ramadhan',
    pretext: 'e.g., family buka puasa with kurma, mak preparing bubur lambuk, family sahur time, anak helping masak lemang'
  },
  {
    title: 'Festive Activities',
    description: 'Fun Malaysian Ramadhan night activities',
    pretext: 'e.g., kids playing mercun, hanging pelita lights, shopping for baju raya, decorating rumah with ketupat'
  }
] as const;

export function RamadhanForm({ onImageGenerated, setIsLoading }: RamadhanFormProps) {
  const [scene, setScene] = useState('');
  const [sceneDetails, setSceneDetails] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!scene.trim() || !sceneDetails.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a black and white line art coloring page of a Malaysian Ramadhan scene featuring ${scene} with ${sceneDetails}. The design should include:
      - Clean, clear outlines in traditional Malaysian kampung style
      - Authentic Malaysian Ramadhan elements (pelita, ketupat, etc)
      - People wearing baju kurung, baju melayu, songkok, tudung
      - Malaysian architectural details (kampung house, surau, etc)
      - Simple background elements showing Malaysian setting
      - White background with no solid fills
      - Easy-to-color spaces between elements
      - Cheerful and festive Ramadhan atmosphere
      Make it suitable for both children and adults with moderate detail level, focusing on Malaysian cultural authenticity.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Choose a Scene
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {SCENE_SUGGESTIONS.map((suggestion) => (
              <button
                key={suggestion.title}
                type="button"
                onClick={() => setScene(suggestion.title)}
                className={`p-4 rounded-lg border-2 transition-colors text-left ${
                  scene === suggestion.title
                    ? 'border-emerald-500 bg-emerald-50 dark:bg-emerald-900/20'
                    : 'border-gray-200 dark:border-gray-700 hover:border-emerald-200 dark:hover:border-emerald-800'
                }`}
              >
                <h3 className="font-medium text-gray-900 dark:text-white mb-1">
                  {suggestion.title}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {suggestion.description}
                </p>
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex justify-between items-baseline">
            <label htmlFor="sceneDetails" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Describe Your Scene
            </label>
            {scene && (
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {SCENE_SUGGESTIONS.find(s => s.title === scene)?.pretext}
              </span>
            )}
          </div>
          <input
            id="sceneDetails"
            type="text"
            value={sceneDetails}
            onChange={(e) => setSceneDetails(e.target.value)}
            placeholder={scene ? SCENE_SUGGESTIONS.find(s => s.title === scene)?.pretext : 'Describe your Ramadhan scene...'}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            required
          />
        </div>

        <button
          type="submit"
          className="w-full bg-emerald-600 hover:bg-emerald-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Moon className="w-5 h-5" />
          Generate Ramadhan Scene
        </button>
      </form>
    </>
  );
}