import React, { useState } from 'react';
import { Palette } from 'lucide-react';
import { PageLayout } from '../components/layout/PageLayout';
import { CoverGeneratorForm } from '../components/CoverGeneratorForm';
import { ImagePreview } from '../components/ImagePreview';

function CoverGenerator() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="Cover Generator"
      description="Create beautiful coloring book covers with custom designs!"
      icon={<Palette className="w-10 h-10 text-blue-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <CoverGeneratorForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default CoverGenerator;