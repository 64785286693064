import React, { useState } from 'react';
import { Eye } from 'lucide-react';
import { PageLayout } from '../../components/layout/PageLayout';
import { POVSceneForm } from '../../components/POVSceneForm';
import { ImagePreview } from '../../components/ImagePreview';

function POVScene() {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PageLayout
      title="POV Scene"
      description="Create immersive point-of-view scenes for coloring!"
      icon={<Eye className="w-10 h-10 text-indigo-500" />}
      showBackButton={false}
    >
      <div className="p-6 space-y-8">
        <POVSceneForm 
          onImageGenerated={setImageUrl}
          setIsLoading={setIsLoading}
        />
        <ImagePreview imageUrl={imageUrl} isLoading={isLoading} />
      </div>
    </PageLayout>
  );
}

export default POVScene;