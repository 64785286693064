import React from 'react';
import { Outlet, Routes, Route } from 'react-router-dom';
import { CategoryLayout } from '../../components/layout/CategoryLayout';
import { Palette, Ghost, Car, Cookie, Laugh, Bird, Zap, Text, Flag, Clover, Heart, Flower, Moon, Book } from 'lucide-react';
import CoverSimpleEasy from './CoverSimpleEasy';
import CoverMonsterFun from './CoverMonsterFun';
import CoverHappyVehicles from './CoverHappyVehicles';
import CoverCuteFood from './CoverCuteFood';
import CoverFlyingBirds from './CoverFlyingBirds';
import SimpleEasy from './SimpleEasy';
import SuperheroName from './SuperheroName';
import MonsterFun from './MonsterFun';
import HappyVehicles from './HappyVehicles';
import FoodColoring from './FoodColoring';
import CircusClowns from './CircusClowns';
import FlyingBirds from './FlyingBirds';
import AlphabetPage from './AlphabetPage';
import DoodleLetters from './DoodleLetters';
import EmojiStyle from './EmojiStyle';
import FlagGenerator from './FlagGenerator';
import StPatricks from './StPatricks';
import FathersDay from './FathersDay';
import MothersDay from './MothersDay';
import RamadhanPage from './RamadhanPage';
import CoverGenerator from '../CoverGenerator';

const tools = [
  {
    id: 'cover-generator',
    title: 'Cover Generator',
    description: 'Create custom coloring book covers',
    icon: Book,
    path: 'cover-generator'
  },
  {
    id: 'ramadhan',
    title: 'Ramadhan Coloring',
    description: 'Create Ramadhan-themed designs',
    icon: Moon,
    path: 'ramadhan'
  },
  {
    id: 'mothers-day',
    title: 'Mother\'s Day Doodles',
    description: 'Create special Mother\'s Day designs',
    icon: Flower,
    path: 'mothers-day'
  },
  {
    id: 'fathers-day',
    title: 'Father\'s Day Doodles',
    description: 'Create special Father\'s Day designs',
    icon: Heart,
    path: 'fathers-day'
  },
  {
    id: 'st-patricks',
    title: 'St. Patrick\'s Day',
    description: 'Create festive St. Patrick\'s Day designs',
    icon: Clover,
    path: 'st-patricks'
  },
  {
    id: 'flag-generator',
    title: 'Flag Generator',
    description: 'Create beautiful flag designs',
    icon: Flag,
    path: 'flag-generator'
  },
  {
    id: 'alphabet',
    title: 'Alphabet Art',
    description: 'Create fun alphabet learning pages',
    icon: Text,
    path: 'alphabet'
  },
  {
    id: 'doodle-letters',
    title: 'Doodle Letters',
    description: 'Create fun doodle-filled letters',
    icon: Text,
    path: 'doodle-letters'
  },
  {
    id: 'emoji-style',
    title: 'Emoji Style',
    description: 'Create cute emoji-style designs',
    icon: Palette,
    path: 'emoji-style'
  },
  {
    id: 'cover-simple',
    title: 'Cover - Simple Easy',
    description: 'Create simple and easy covers',
    icon: Zap,
    path: 'cover-simple'
  },
  {
    id: 'cover-monster',
    title: 'Cover - Monster Fun',
    description: 'Create fun monster-themed covers',
    icon: Ghost,
    path: 'cover-monster'
  },
  {
    id: 'cover-vehicles',
    title: 'Cover - Happy Vehicles',
    description: 'Create cheerful vehicle-themed covers',
    icon: Car,
    path: 'cover-vehicles'
  },
  {
    id: 'cover-food',
    title: 'Cover - Cute Food',
    description: 'Create adorable food-themed covers',
    icon: Cookie,
    path: 'cover-food'
  },
  {
    id: 'cover-flying-birds',
    title: 'Cover - Flying Birds',
    description: 'Create delightful flying bird covers',
    icon: Bird,
    path: 'cover-flying-birds'
  },
  {
    id: 'simple',
    title: 'Simple Easy',
    description: 'Create simple and easy designs',
    icon: Zap,
    path: 'simple'
  },
  {
    id: 'superhero-name',
    title: 'Superhero Name',
    description: 'Design superhero-themed names',
    icon: Laugh,
    path: 'superhero-name'
  },
  {
    id: 'monster-fun',
    title: 'Monster Fun',
    description: 'Create fun monster designs',
    icon: Ghost,
    path: 'monster-fun'
  },
  {
    id: 'happy-vehicles',
    title: 'Happy Vehicles',
    description: 'Design cheerful vehicle illustrations',
    icon: Car,
    path: 'happy-vehicles'
  },
  {
    id: 'food-coloring',
    title: 'Food Coloring',
    description: 'Create cute food-themed designs',
    icon: Cookie,
    path: 'food-coloring'
  },
  {
    id: 'circus-clowns',
    title: 'Circus Clowns',
    description: 'Design fun circus clown scenes',
    icon: Laugh,
    path: 'circus-clowns'
  },
  {
    id: 'flying-birds',
    title: 'Flying Birds',
    description: 'Create delightful bird illustrations',
    icon: Bird,
    path: 'flying-birds'
  }
];

function KidsLayout() {
  return (
    <CategoryLayout
      title="Kids Corner"
      description="Simple and fun designs perfect for little ones"
      icon={Palette}
      color="blue"
      tools={tools}
    >
      <Routes>
        <Route path="alphabet" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><AlphabetPage /></div>} />
        <Route path="doodle-letters" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><DoodleLetters /></div>} />
        <Route path="emoji-style" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><EmojiStyle /></div>} />
        <Route path="cover-simple" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><CoverSimpleEasy /></div>} />
        <Route path="cover-monster" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><CoverMonsterFun /></div>} />
        <Route path="cover-vehicles" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><CoverHappyVehicles /></div>} />
        <Route path="cover-food" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><CoverCuteFood /></div>} />
        <Route path="cover-flying-birds" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><CoverFlyingBirds /></div>} />
        <Route path="simple" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><SimpleEasy /></div>} />
        <Route path="superhero-name" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><SuperheroName /></div>} />
        <Route path="monster-fun" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><MonsterFun /></div>} />
        <Route path="happy-vehicles" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><HappyVehicles /></div>} />
        <Route path="food-coloring" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><FoodColoring /></div>} />
        <Route path="circus-clowns" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><CircusClowns /></div>} />
        <Route path="flying-birds" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><FlyingBirds /></div>} />
        <Route path="flag-generator" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><FlagGenerator /></div>} />
        <Route path="st-patricks" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><StPatricks /></div>} />
        <Route path="fathers-day" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><FathersDay /></div>} />
        <Route path="mothers-day" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><MothersDay /></div>} />
        <Route path="ramadhan" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><RamadhanPage /></div>} />
        <Route path="cover-generator" element={<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg"><CoverGenerator /></div>} />
      </Routes>
    </CategoryLayout>
  );
}

export default KidsLayout;