import React, { useState } from 'react';
import { Heart } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface FathersDayFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const NICKNAME_OPTIONS = [
  {
    category: 'Common Terms',
    terms: ['Dad', 'Daddy', 'Father', 'Papa', 'Pop', 'Pops']
  },
  {
    category: 'Family Relations',
    terms: [
      'Bachelor',
      'Boyfriend',
      'Brother',
      'Brother in Law',
      'Ex-Husband',
      'Father-In-Law',
      'Grandson',
      'Half Brother',
      'Husband',
      'Nephew',
      'Son',
      'Son In Law',
      'Stepbrother',
      'Stepdad',
      'StepFather',
      'Stepson',
      'Uncle'
    ]
  },
  {
    category: 'Grandpa Terms',
    terms: [
      'Big Papa',
      'G-Pa',
      'Gampy',
      'Grandad',
      'Granddaddy',
      'Grandfather',
      'Grandpa',
      'Grandpapa',
      'Grandpop',
      'Gramps',
      'Great-Grandpa',
      'Nonno',
      'Opa',
      'Pampie',
      'Pap',
      'Papaw',
      'Papi',
      'Pappy',
      'Peepaw',
      'Pop-Pop'
    ]
  },
  {
    category: 'Multilingual Terms',
    terms: [
      'Abba',
      'Abi',
      'Baba',
      'Babbo',
      'Padre',
      'Papá',
      'Père',
      'Tata',
      'Vater'
    ]
  }
] as const;

export function FathersDayForm({ onImageGenerated, setIsLoading }: FathersDayFormProps) {
  const [nickname, setNickname] = useState('');
  const [customText, setCustomText] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!nickname) {
      toast.error('Please select a nickname');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const messageText = customText ? `${nickname} - ${customText}` : nickname;
      const prompt = `Create a Father's Day themed doodle art coloring page featuring the text "${messageText}" in a decorative style. The design should include:
      - Large, clear letters with doodle patterns inside
      - Simple Father's Day themed elements (tools, ties, hearts) integrated around the text
      - Clean, single-line outlines (NO solid black fills)
      - Minimal shading or hatching
      - White background
      - Easy-to-color spaces between elements
      - Playful and loving style
      Make it perfect for coloring with clear sections and simple details.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="nickname" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Choose a Nickname
          </label>
          <div className="relative">
            <input
              id="nickname"
              type="text"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              onFocus={() => setShowDropdown(true)}
              placeholder="Select or type a nickname"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              required
            />
            
            {showDropdown && (
              <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600 max-h-60 overflow-auto">
                {NICKNAME_OPTIONS.map((category) => (
                  <div key={category.category}>
                    <div className="px-4 py-2 bg-gray-50 dark:bg-gray-800 text-sm font-medium text-gray-700 dark:text-gray-300">
                      {category.category}
                    </div>
                    {category.terms.map((term) => (
                      <button
                        key={`${category.category}-${term}`}
                        type="button"
                        onClick={() => {
                          setNickname(term);
                          setShowDropdown(false);
                        }}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-white"
                      >
                        {term}
                      </button>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div>
          <label htmlFor="customText" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Add Custom Message (Optional)
          </label>
          <input
            id="customText"
            type="text"
            value={customText}
            onChange={(e) => setCustomText(e.target.value)}
            placeholder="e.g., We Love You, You're The Best"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Heart className="w-5 h-5" />
          Generate Father's Day Design
        </button>
      </form>
    </>
  );
}