import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { DivideIcon as LucideIcon } from 'lucide-react';
import { CreditGuard } from '../CreditGuard';

interface CategoryLayoutProps {
  title: string;
  description: string;
  icon: LucideIcon;
  color: string;
  tools: {
    id: string;
    title: string;
    description: string;
    icon: LucideIcon;
    path: string;
  }[];
  children?: React.ReactNode;
}

export function CategoryLayout({ title, description, icon: Icon, color, tools, children }: CategoryLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const isRoot = location.pathname.split('/').length <= 2;

  return (
    <CreditGuard>
      <div className="min-h-[calc(100vh-2rem)]">
        {/* Only show header on category root pages */}
        {isRoot && (
          <div className="mb-8">
            <div className="flex items-center justify-center gap-2 mb-4">
              <Icon className={`w-8 h-8 sm:w-10 sm:h-10 text-${color}-500`} />
              <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">{title}</h1>
            </div>
            <p className="text-sm sm:text-lg text-gray-600 text-center max-w-2xl mx-auto">
              {description}
            </p>
          </div>
        )}

        {isRoot ? (
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {tools.map((tool) => {
              const ToolIcon = tool.icon;
              return (
                <button
                  key={tool.id}
                  onClick={() => navigate(tool.path)}
                  className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 text-left group border border-gray-100 dark:border-gray-700"
                >
                  <div className="flex items-start justify-between mb-4">
                    <div className={`p-2 sm:p-3 bg-${color}-50 dark:bg-${color}-900/20 rounded-lg group-hover:scale-110 transition-transform`}>
                      <ToolIcon className={`w-5 h-5 sm:w-6 sm:h-6 text-${color}-500 dark:text-${color}-400`} />
                    </div>
                  </div>
                  <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-2">{tool.title}</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">{tool.description}</p>
                </button>
              );
            })}
          </div>
        ) : (
          children || <Outlet />
        )}
      </div>
    </CreditGuard>
  );
}