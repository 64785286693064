import React, { useState } from 'react';
import { Palette } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface CoverGeneratorFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const AGE_RANGES = [
  '4-8 years',
  '8-12 years',
  'Teens & Adults'
] as const;

const STYLE_OPTIONS = [
  'Cute & Playful',
  'Educational',
  'Fantasy',
  'Adventure',
  'Nature',
  'Whimsical'
] as const;

export function CoverGeneratorForm({ onImageGenerated, setIsLoading }: CoverGeneratorFormProps) {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [ageRange, setAgeRange] = useState<string>(AGE_RANGES[0]);
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [mainElement, setMainElement] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim() || !mainElement.trim()) {
      toast.error('Please fill in all required fields');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a professional 2D coloring book cover design in ${style.toLowerCase()} style for ages ${ageRange}. The design should include:

1. TITLE: "${title}" in large, bold, playful letters with a decorative style
${subtitle ? `2. SUBTITLE: "${subtitle}" in smaller text\n` : ''}
3. MAIN ILLUSTRATION: ${mainElement}

Design Requirements:
- Pure 2D illustration style with NO depth or perspective
- Clean, professional outlines suitable for coloring
- Flat shapes with clear spaces between elements
- NO gradients, shading, or textures
- Cheerful characters with expressive faces
- Simple decorative borders or frames
- Age-appropriate style for ${ageRange}

Layout Requirements:
- Title prominently displayed at top
- Main illustration as central focus
- Balanced composition with good spacing
- Age range text in small font
- Subtitle below title (if provided)
- Simple background elements that don't overwhelm

The final design should look like a professional, retail-ready coloring book cover with a cohesive style and clear visual hierarchy.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate cover');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Book Title
            </label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="e.g., Magical Unicorns"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              required
            />
          </div>

          <div>
            <label htmlFor="subtitle" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Subtitle (Optional)
            </label>
            <input
              id="subtitle"
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              placeholder="e.g., Fun and Magical Adventures to Color!"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            />
          </div>
        </div>

        <div>
          <label htmlFor="mainElement" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Main Cover Element
          </label>
          <input
            id="mainElement"
            type="text"
            value={mainElement}
            onChange={(e) => setMainElement(e.target.value)}
            placeholder="e.g., a cute unicorn with rainbow mane surrounded by stars and butterflies"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Age Range
          </label>
          <div className="grid grid-cols-3 gap-2">
            {AGE_RANGES.map((range) => (
              <button
                key={range}
                type="button"
                onClick={() => setAgeRange(range)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  ageRange === range
                    ? 'bg-blue-100 dark:bg-blue-900/40 text-blue-700 dark:text-blue-400 border-2 border-blue-500'
                    : 'bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {range}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Cover Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-blue-100 dark:bg-blue-900/40 text-blue-700 dark:text-blue-400 border-2 border-blue-500'
                    : 'bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Palette className="w-5 h-5" />
          Generate Cover Design
        </button>
      </form>
    </>
  );
}