import React, { useState } from 'react';
import { Clover } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface StPatricksFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const THEME_SUGGESTIONS = [
  'Lucky Leprechaun',
  'Pot of Gold',
  'Rainbow Bridge',
  'Dancing Shamrocks',
  'Celtic Patterns',
  'Irish Castle',
  'St. Patrick\'s Parade',
  'Lucky Horseshoe'
] as const;

const STYLE_OPTIONS = [
  'Cute',
  'Whimsical',
  'Playful',
  'Magical',
  'Festive',
  'Cheerful'
] as const;

export function StPatricksForm({ onImageGenerated, setIsLoading }: StPatricksFormProps) {
  const [theme, setTheme] = useState('');
  const [style, setStyle] = useState<string>(STYLE_OPTIONS[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!theme.trim()) {
      toast.error('Please select a theme');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a ${style.toLowerCase()} St. Patrick's Day coloring page featuring a ${theme}. The design should be child-friendly with:
      - Simple, clean outlines perfect for children to color
      - Clear spaces between elements
      - No solid black fills or heavy shading
      - Cheerful and festive elements
      - White background
      - Minimal detail level suitable for young children
      - Include some simple shamrock outlines and basic Celtic patterns
      Make it fun and engaging for kids while maintaining a clear, easy-to-color style.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="theme" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Choose a Theme
          </label>
          <div className="relative">
            <input
              id="theme"
              type="text"
              value={theme}
              onChange={(e) => setTheme(e.target.value)}
              placeholder="e.g., Lucky Leprechaun, Pot of Gold"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-green-500 focus:border-green-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              required
            />
          </div>
          
          <div className="mt-3">
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Quick suggestions:</p>
            <div className="flex flex-wrap gap-2">
              {THEME_SUGGESTIONS.map((suggestion) => (
                <button
                  key={suggestion}
                  type="button"
                  onClick={() => setTheme(suggestion)}
                  className="px-3 py-1 text-sm bg-green-50 dark:bg-green-900/20 text-green-700 dark:text-green-400 rounded-full hover:bg-green-100 dark:hover:bg-green-900/40 transition-colors"
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Art Style
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {STYLE_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => setStyle(option)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  style === option
                    ? 'bg-green-100 dark:bg-green-900/40 text-green-700 dark:text-green-400 border-2 border-green-500'
                    : 'bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Clover className="w-5 h-5" />
          Generate St. Patrick's Design
        </button>
      </form>
    </>
  );
}