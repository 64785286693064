import React, { useState } from 'react';
import { Palette } from 'lucide-react';
import { LoadingOverlay } from '../LoadingOverlay';
import toast from 'react-hot-toast';

interface ColoringIdeasFormProps {
  onIdeasGenerated: (ideas: string[]) => void;
  setIsLoading: (loading: boolean) => void;
}

export function ColoringIdeasForm({ onIdeasGenerated, setIsLoading }: ColoringIdeasFormProps) {
  const [theme, setTheme] = useState('');
  const [count, setCount] = useState(10);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!theme.trim()) {
      toast.error('Please enter a theme');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Generate ${count} coloring page ideas based on the theme: ${theme}.

      Format as a simple bullet-point list with:
      • One concept per line
      • No descriptions or explanations
      • Just the core idea
      • One to three words maximum
      • No articles (a, an, the)
      • No punctuation
      • No numbering
      • Start each line with a bullet point (•)
      
      Example format:
      • Dancing Unicorn
      • Space Rocket
      • Forest Fairy`;
      
      const response = await fetch(`${import.meta.env.VITE_AZURE_OPENAI_ENDPOINT}/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-02-15-preview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': import.meta.env.VITE_AZURE_OPENAI_KEY,
        },
        body: JSON.stringify({
          messages: [{ role: 'user', content: prompt }],
          max_tokens: 2000,
          temperature: 0.2,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate ideas');
      }

      const data = await response.json();
      const ideas = data.choices[0].message.content
        .split('\n')
        .filter((line: string) => line.trim().startsWith('•'))
        .map((idea: string) => idea.trim().replace('• ', ''));

      onIdeasGenerated(ideas);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate ideas');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerate} className="space-y-6">
        <div className="space-y-4">
          <div>
            <label htmlFor="theme" className="block text-sm font-medium text-gray-700 mb-1">
              What theme would you like ideas for?
            </label>
            <input
              id="theme"
              type="text"
              value={theme}
              onChange={(e) => setTheme(e.target.value)}
              placeholder="e.g., ocean life, space adventure, fairy garden"
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              required
            />
          </div>

          <div>
            <label htmlFor="count" className="block text-sm font-medium text-gray-700 mb-1">
              Number of ideas
            </label>
            <input
              id="count"
              type="number"
              min="1"
              max="20"
              value={count}
              onChange={(e) => setCount(Number(e.target.value))}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <Palette className="w-5 h-5" />
          Generate Coloring Ideas
        </button>
      </form>
    </>
  );
}