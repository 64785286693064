import React from 'react';
import { Book, ExternalLink, Wand2, BookOpen } from 'lucide-react';

interface Tool {
  title: string;
  description: string;
  url: string;
  icon: typeof Book;
  color: string;
}

export default function PublishingTools() {
  const tools: Tool[] = [
    {
      title: 'Word Activity Generator',
      description: 'Create engaging activity book content in less than 5 minutes! Generate word searches, crosswords, mazes, and more with AI assistance.',
      url: 'https://wordactivity.com',
      icon: Wand2,
      color: 'blue'
    },
    {
      title: 'BookCreator AI',
      description: 'Software to generate high content books using AI. Perfect for KDP Self Publishers looking to create quality content efficiently.',
      url: 'https://bookcreator.my',
      icon: BookOpen,
      color: 'purple'
    },
    {
      title: 'KDP All-in-One Tools',
      description: 'Everything you need to publish on Amazon KDP - keyword research, category finder, description generator, and more tools to boost your publishing success.',
      url: 'https://kdp.im',
      icon: Book,
      color: 'green'
    }
  ];

  return (
    <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto space-y-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Publishing Tools</h1>
          <p className="mt-2 text-gray-600 dark:text-gray-400">Essential tools to help you create and publish your books</p>
        </div>

        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {tools.map((tool) => {
            const Icon = tool.icon;
            return (
              <a
                key={tool.url}
                href={tool.url}
                target="_blank"
                rel="noopener noreferrer"
                className="group bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-700"
              >
                <div className="flex flex-col space-y-4">
                  <div className="flex items-center justify-between">
                    <div className={`p-3 bg-${tool.color}-50 dark:bg-${tool.color}-900/20 rounded-lg group-hover:scale-110 transition-transform`}>
                      <Icon className={`w-6 h-6 text-${tool.color}-500 dark:text-${tool.color}-400`} />
                    </div>
                    <ExternalLink className="w-5 h-5 text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-400" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{tool.title}</h3>
                  <p className="text-gray-600 dark:text-gray-400">{tool.description}</p>
                </div>
              </a>
            );
          })}
        </div>

        <div className="mt-8 p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
          <p className="text-sm text-center text-blue-600 dark:text-blue-400">
            More tools coming soon! Stay tuned for updates.
          </p>
        </div>
      </div>
    </div>
  );
}