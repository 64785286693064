import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from './components/ThemeProvider';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import Dashboard from './pages/Dashboard';
import KidsLayout from './pages/kids/KidsLayout';
import AdultLayout from './pages/adult/AdultLayout';
import CozyLayout from './pages/cozy/CozyLayout';
import DoodleLayout from './pages/doodle/DoodleLayout';
import ContentLayout from './pages/content/ContentLayout';
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from './pages/SignupPage';
import { AccountSettings } from './pages/AccountSettings';
import { AdminRoute } from './components/AdminRoute';
import { DashboardLayout } from './components/layout/DashboardLayout';
import AdminPortal from './pages/admin/AdminPortal';
import UserManagement from './pages/admin/UserManagement';
import SystemSettings from './pages/admin/SystemSettings';
import CreditManagement from './pages/admin/CreditManagement';
import PaymentPage from './pages/PaymentPage';
import PaymentSuccess from './pages/PaymentSuccess';
import Tutorial from './pages/Tutorial';
import PublishingTools from './pages/PublishingTools';


export function App() {
  return (
    <>
      <ThemeProvider>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup/one-time" element={<SignupPage type="one-time" />} />
            <Route path="/signup/subscription" element={<SignupPage type="subscription" />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />

            {/* Protected Routes */}
            <Route element={<DashboardLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="kids/*" element={<KidsLayout />} />
              <Route path="adult/*" element={<AdultLayout />} />
              <Route path="cozy/*" element={<CozyLayout />} />
              <Route path="doodle/*" element={<DoodleLayout />} />
              <Route path="content/*" element={<ContentLayout />} />
              <Route path="tutorial" element={<Tutorial />} />
              <Route path="publishing-tools" element={<PublishingTools />} />
              <Route path="account" element={<AccountSettings />} />
              
              {/* Admin Routes */}
              <Route path="admin" element={<AdminRoute><AdminPortal /></AdminRoute>} />
              <Route path="admin/users" element={<AdminRoute><UserManagement /></AdminRoute>} />
              <Route path="admin/settings" element={<AdminRoute><SystemSettings /></AdminRoute>} />
              <Route path="admin/credits" element={<AdminRoute><CreditManagement /></AdminRoute>} />
            </Route>
          </Routes>

        <Toaster position="top-center" />
      </ThemeProvider>
    </>
  );
}

export default App;