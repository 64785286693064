import React, { useState } from 'react';
import { GraduationCap } from 'lucide-react';
import { LoadingOverlay } from './LoadingOverlay';
import toast from 'react-hot-toast';
import { useImageGeneration } from '../hooks/useImageGeneration';

interface ClassroomSceneFormProps {
  onImageGenerated: (imageUrl: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const SCENE_SUGGESTIONS = [
  'art class painting',
  'science experiment',
  'story time circle',
  'music lesson',
  'physical education',
  'computer lab',
  'library reading',
  'math class'
] as const;

const ACTIVITY_TYPES = [
  'Learning',
  'Playing',
  'Creating',
  'Presenting',
  'Group Work',
  'Reading'
] as const;

export function ClassroomSceneForm({ onImageGenerated, setIsLoading }: ClassroomSceneFormProps) {
  const [scene, setScene] = useState('');
  const [activity, setActivity] = useState<string>(ACTIVITY_TYPES[0]);
  const [isGenerating, setIsGenerating] = useState(false);
  const { generateWithCredits } = useImageGeneration();

  const handleGenerateImage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!scene.trim()) {
      toast.error('Please describe the scene');
      return;
    }

    setIsGenerating(true);
    setIsLoading(true);
    try {
      const prompt = `Create a black and white line art coloring page of a classroom scene with students ${activity.toLowerCase()} during ${scene}. The design should include:
      - Clean, clear outlines perfect for coloring
      - Students engaged in educational activities
      - Classroom furniture and educational materials
      - Simple background elements showing a school setting
      - White background with no solid fills
      - Easy-to-color spaces between elements
      - Cheerful and engaging learning atmosphere
      Make it fun and educational with good interaction between students and clear details of classroom elements.`;
      
      const imageUrl = await generateWithCredits(prompt);
      if (imageUrl) {
        onImageGenerated(imageUrl);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to generate image');
    } finally {
      setIsGenerating(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isGenerating && <LoadingOverlay />}
      <form onSubmit={handleGenerateImage} className="space-y-6">
        <div>
          <label htmlFor="scene" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Choose a Class Scene
          </label>
          <input
            id="scene"
            type="text"
            value={scene}
            onChange={(e) => setScene(e.target.value)}
            placeholder="e.g., art class painting, science experiment"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-purple-500 focus:border-purple-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white mb-2"
            required
          />
          <div className="flex flex-wrap gap-2">
            {SCENE_SUGGESTIONS.map((suggestion) => (
              <button
                key={suggestion}
                type="button"
                onClick={() => setScene(suggestion)}
                className="px-3 py-1 text-sm bg-purple-50 dark:bg-purple-900/20 text-purple-700 dark:text-purple-400 rounded-full hover:bg-purple-100 dark:hover:bg-purple-900/40 transition-colors"
              >
                {suggestion}
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Student Activity
          </label>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
            {ACTIVITY_TYPES.map((type) => (
              <button
                key={type}
                type="button"
                onClick={() => setActivity(type)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  activity === type
                    ? 'bg-purple-100 dark:bg-purple-900/40 text-purple-700 dark:text-purple-400 border-2 border-purple-500'
                    : 'bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
        >
          <GraduationCap className="w-5 h-5" />
          Generate Classroom Scene
        </button>
      </form>
    </>
  );
}