import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../lib/firebase';
import { Navigate, Outlet } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { LoadingSpinner } from '../LoadingSpinner';

export function DashboardLayout() {
  const [user, loading] = useAuthState(auth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <div className="flex min-h-screen w-full bg-pearl dark:bg-gray-900">
      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} onToggle={() => setIsSidebarOpen(!isSidebarOpen)} />

      {/* Main Content */}
      <main className="flex-1 w-full pt-16 lg:pt-8 bg-pearl dark:bg-gray-900">
        <div className="px-4 sm:px-6 lg:px-8 w-full">
            <Outlet />
        </div>
      </main>
    </div>
  );
}