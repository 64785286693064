import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../lib/firebase'; 
import { UserCircle, Shield, Trash2, Lock, CreditCard, ChevronLeft, ChevronRight, Edit, Save, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { AdminService } from '../../services/adminService';
import toast from 'react-hot-toast';
import { query, orderBy, limit, startAfter, getDocs, endBefore, limitToLast, updateDoc, addDoc } from 'firebase/firestore';

interface User {
  id: string;
  email: string;
  displayName?: string;
  role: string;
  creditsAvailable: number;
  createdAt: string;
}

export function UserList() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [isUpdatingRole, setIsUpdatingRole] = useState<string | null>(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [firstVisible, setFirstVisible] = useState<any>(null);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const USERS_PER_PAGE = 10;
  const [editingCredits, setEditingCredits] = useState<string | null>(null);
  const [newCredits, setNewCredits] = useState<string>('');
  const [editingReason, setEditingReason] = useState<string>('');

  const handleSyncUsers = async () => {
    setIsSyncing(true);
    try {
      const idToken = await auth.currentUser?.getIdToken();
      if (!idToken) {
        throw new Error('Not authenticated');
      }

      const response = await fetch('/.netlify/functions/sync-auth-users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to sync users');
      }

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to sync users');
      }
      
      toast.success(`Successfully synced ${data.syncedCount} users`);
    } catch (error) {
      console.error('Error syncing users:', error);
      toast.error('Failed to sync users');
    } finally {
      setIsSyncing(false);
    }
  };

  const handleEditCredits = (userId: string, currentCredits: number) => {
    setEditingCredits(userId);
    setNewCredits(currentCredits.toString());
    setEditingReason('');
  };

  const handleSaveCredits = async (userId: string) => {
    if (!newCredits || !editingReason) {
      toast.error('Please enter both credits and reason');
      return;
    }

    const credits = parseInt(newCredits);
    if (isNaN(credits) || credits < 0) {
      toast.error('Please enter a valid number of credits');
      return;
    }

    try {
      const userRef = doc(db, 'users', userId);
      const user = users.find(u => u.id === userId);
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error('Not authenticated');
      }
      
      // Add to credit history
      await addDoc(collection(db, 'creditHistory'), {
        userId,
        previousAmount: user?.creditsAvailable || 0,
        newAmount: credits,
        reason: editingReason,
        modifiedBy: currentUser.email,
        timestamp: new Date().toISOString()
      });

      // Update user credits
      await updateDoc(userRef, {
        creditsAvailable: credits,
        updatedAt: new Date().toISOString()
      });

      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.id === userId ? { ...u, creditsAvailable: credits } : u
        )
      );

      toast.success('Credits updated successfully');
      setEditingCredits(null);
      setNewCredits('');
      setEditingReason('');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to update credits';
      console.error('Error updating credits:', errorMessage);
      toast.error('Failed to update credits');
    }
  };

  const fetchUsers = async (direction: 'next' | 'prev' = 'next') => {
    setIsLoading(true);
    try {
      let q = query(
        collection(db, 'users'),
        orderBy('createdAt', 'desc'),
        limit(USERS_PER_PAGE)
      );

      if (direction === 'next' && lastVisible) {
        q = query(
          collection(db, 'users'),
          orderBy('createdAt', 'desc'),
          startAfter(lastVisible),
          limit(USERS_PER_PAGE)
        );
      } else if (direction === 'prev' && firstVisible) {
        q = query(
          collection(db, 'users'),
          orderBy('createdAt', 'desc'),
          endBefore(firstVisible),
          limitToLast(USERS_PER_PAGE)
        );
      }

      const snapshot = await getDocs(q);
      const userData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as User[];

      if (!snapshot.empty) {
        setFirstVisible(snapshot.docs[0]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      }

      setUsers(userData);

      // Get total count for pagination
      const totalSnapshot = await getDocs(collection(db, 'users'));
      const totalPages = Math.ceil(totalSnapshot.size / USERS_PER_PAGE);
      setTotalPages(totalPages);

    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Failed to load users');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      await fetchUsers('next');
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      await fetchUsers('prev');
      setCurrentPage(prev => prev - 1);
    }
  };

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser || currentUser.email !== 'qimi.work@gmail.com') {
      navigate('/unauthorized');
      return;
    }

    fetchUsers();
  }, [navigate, auth.currentUser?.email]);

  const handleUpdateRole = async (userId: string, newRole: string) => {
    const user = users.find(u => u.id === userId);
    if (!user) return;
    
    if (!auth.currentUser?.email) {
      toast.error('Authentication required');
      return;
    }

    setIsUpdatingRole(userId);

    try {
      const response = await fetch('/.netlify/functions/update-user-role', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-email': auth.currentUser.email
        },
        body: JSON.stringify({
          userId,
          role: newRole,
        }),
      });

      if (!response.ok) {
        const text = await response.text();
        let errorData;
        try {
          errorData = JSON.parse(text);
        } catch {
          throw new Error('Invalid server response');
        }
        throw new Error(errorData.error || errorData.message || 'Failed to update user role');
      }

      const data = await response.json();

      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.id === userId ? { ...u, ...data.updates } : u
        )
      );

      toast.success('User role updated successfully');
    } catch (error) {
      console.error('Error updating role:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to update user role');
    } finally {
      setIsUpdatingRole(null);
    }
  };

  const handleDeleteUser = async (userId: string, email: string) => {
    if (!confirm(`Are you sure you want to delete user ${email}?`)) {
      return;
    }

    setIsDeleting(true);
    try {
      const response = await fetch('/.netlify/functions/delete-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete user');
      }

      await deleteDoc(doc(db, 'users', userId));
      setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      setSelectedUsers(prev => prev.filter(id => id !== userId));
      
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to delete user');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSendPasswordReset = async (email: string) => {
    setIsSendingReset(true);
    try {
      const response = await fetch('/.netlify/functions/send-password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send password reset');
      }

      toast.success('Password reset email sent successfully');
    } catch (error) {
      console.error('Error sending password reset:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to send password reset');
    } finally {
      setIsSendingReset(false);
    }
  };

  return (
    <div className="overflow-x-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <div className="mb-4 flex justify-end">
        <button
          onClick={handleSyncUsers}
          disabled={isSyncing}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white rounded-lg transition-colors disabled:opacity-50"
        >
          {isSyncing ? (
            <>
              <Shield className="w-5 h-5 animate-spin" />
              Syncing...
            </>
          ) : (
            <>
              <Shield className="w-5 h-5" />
              Sync Auth Users
            </>
          )}
        </button>
      </div>
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className="bg-gray-50 dark:bg-gray-700">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">User</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Credits</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Role</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {isLoading ? (
            <tr>
              <td colSpan={4} className="px-6 py-4 text-center">
                <div className="flex justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 dark:border-blue-400"></div>
                </div>
              </td>
            </tr>
          ) : users.map((user) => (
            <tr key={user.id}>
              <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-white">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <UserCircle className="h-8 w-8 text-gray-400 dark:text-gray-500" />
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {user.displayName || 'No Name'}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">{user.email}</div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-white">
                <div className="flex items-center">
                  <CreditCard className="w-4 h-4 text-gray-400 dark:text-gray-500 mr-2" />
                  {editingCredits === user.id ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="number"
                        value={newCredits}
                        onChange={(e) => setNewCredits(e.target.value)}
                        className="w-20 px-2 py-1 text-sm border rounded-md dark:bg-gray-700 dark:border-gray-600"
                        min="0"
                      />
                      <input
                        type="text"
                        value={editingReason}
                        onChange={(e) => setEditingReason(e.target.value)}
                        placeholder="Reason for change"
                        className="w-40 px-2 py-1 text-sm border rounded-md dark:bg-gray-700 dark:border-gray-600"
                      />
                      <button
                        onClick={() => handleSaveCredits(user.id)}
                        className="p-1 text-green-600 hover:text-green-700 dark:text-green-400"
                        title="Save changes"
                      >
                        <Save className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => setEditingCredits(null)}
                        className="p-1 text-red-600 hover:text-red-700 dark:text-red-400"
                        title="Cancel"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray-900 dark:text-white">
                        {user.creditsAvailable || 0}
                      </span>
                      <button
                        onClick={() => handleEditCredits(user.id, user.creditsAvailable || 0)}
                        className="p-1 text-blue-600 hover:text-blue-700 dark:text-blue-400"
                        title="Edit credits"
                      >
                        <Edit className="w-4 h-4" />
                      </button>
                    </div>
                  )}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <select
                  value={user.role}
                  onChange={(e) => handleUpdateRole(user.id, e.target.value)}
                  disabled={isUpdatingRole === user.id}
                  className="block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:bg-gray-100 dark:bg-gray-700 dark:text-white dark:disabled:bg-gray-600"
                >
                  <option value="one-time-credit">One Time Credit (250 credits)</option>
                  <option value="subscription">Subscription (300 credits/month)</option>
                  <option value="unlimited">Unlimited</option>
                </select>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleSendPasswordReset(user.email)}
                    disabled={isSendingReset}
                    className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300 disabled:opacity-50"
                    title="Send password reset email"
                  >
                    <Lock className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDeleteUser(user.id, user.email)}
                    disabled={isDeleting}
                    className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300 disabled:opacity-50"
                    title="Delete user"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <div className="flex items-center justify-between px-4 py-3 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
        <div className="flex-1 flex justify-between sm:hidden">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1 || isLoading}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages || isLoading}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700 dark:text-gray-300">
              Page <span className="font-medium">{currentPage}</span> of{' '}
              <span className="font-medium">{totalPages}</span>
            </p>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1 || isLoading}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-500 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages || isLoading}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm font-medium text-gray-500 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-600 disabled:opacity-50"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}